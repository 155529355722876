import i18next from "i18next";
import React, { useState, useEffect, useContext } from "react";
import { Container, Col, Row, Spinner } from "react-bootstrap";
import Axios from "../axios";
import AuthContext from "../context/AuthContext";
import { toast } from "react-hot-toast";
import { handleLogin } from "../components/MyNav/LoginButton";
import party from "party-js";
import AppInitializer from "../utils/Initializer/AppInitializer";
const Oauth = () => {
    const [oauthSuccess, setOauthSuccess] = useState(false);
    const [loading, setLoading] = useState(true);
    const [count, setCount] = useState(3);
    const { login } = useContext(AuthContext);
    const handleCount = () => {
        const intervalID = setInterval(() => {
            setCount((count) => count - 1);
        }, 1000);
        setTimeout(() => {
            clearInterval(intervalID);
            window.location.replace("/");
        }, 2993);
        party.confetti(document.getElementById("confetti"), {
            count: party.variation.range(50, 100),
            size: party.variation.range(0.6, 1.4),
            shapes: ["star", "roundedSquare", "rectangle", "circle"],
        });
    };
    const getUserToken = async () => {
        setLoading(true);
        try {
            const urlParams = new URLSearchParams(window.location.search);
            const response_1 = await Axios.post(
                `${process.env.REACT_APP_API_BASE_URL}authorization/token`,
                { code: urlParams.get("code") }
            );
            const response_2 = await Axios.get(
                `${process.env.REACT_APP_API_BASE_URL}employee-profile/`,
                {
                    headers: {
                        Authorization: `JWT ${response_1.data["omc_token"]}`,
                    },
                }
            );
            const response_3 = await Axios.get(
                `${process.env.REACT_APP_API_BASE_URL}employee-profile-picture/`,
                {
                    headers: {
                        Authorization: `JWT ${response_1.data["omc_token"]}`,
                        "content-type": "image/jpeg",
                    },
                    responseType: "arraybuffer",
                }
            );
            const base64picture = btoa(
                new Uint8Array(response_3.data).reduce(
                    (data, byte) => data + String.fromCharCode(byte),
                    ""
                )
            );
            const imageDataUrl = `data:image/jpeg;base64,${base64picture}`;
            login({
                token: response_1.data["omc_token"],
                employee_profile: response_2.data,
                employee_profile_picture: imageDataUrl,
            });
            setOauthSuccess(true);
            AppInitializer();
            handleCount();
        } catch (err) {
            toast.error(err.message);
            setOauthSuccess(false);
        } finally {
            console.info(`\u2605 Oauth Fired`);
            setLoading(false);
        }
    };
    useEffect(() => {
        getUserToken();
        document.title = i18next.t("common:authentication");
    }, []);
    return (
        <Container className="mt-10">
            <div id="confetti" className="mx-auto col-3"></div>
            {oauthSuccess && !loading && (
                <Row className="row-cols-1 justify-content-center align-items-center">
                    <Col className="mb-3 text-center">
                        <h1>{i18next.t("pages:login_success")}</h1>
                    </Col>
                    <Col className="text-center">
                        <h3>{i18next.t("pages:redirect_home")} {count}s ...</h3>
                    </Col>
                </Row>
            )}
            <Row className="justify-content-center align-items-center">
                {!oauthSuccess && !loading && (
                    <Col xs="auto">
                        <h1>
                            <i className="bi bi-exclamation-triangle-fill text-warning me-3"></i>
                            {i18next.t("pages:login_fail")}{" "}
                            <a
                                style={{ cursor: "pointer" }}
                                onClick={handleLogin}
                                className="link-offset-1-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover"
                            >
                                {i18next.t("common:login")}
                            </a>{" "}
                            {i18next.t("pages:again")}
                        </h1>
                    </Col>
                )}
                {loading && (
                    <>
                        <Col xs="auto">
                            <Spinner animation="border" variant="secondary" />
                        </Col>
                        <Col xs="auto" className="text-secondary">
                            <h1>{i18next.t("common:loading")}</h1>
                        </Col>
                    </>
                )}
            </Row>
        </Container>
    );
};
export default Oauth;
