import capitalize from "capitalize";
/**
 * Handle Initialization function to set all resources to localStorage
 * @param {array} employees  all employees info
 * @param {array} employees_who_have_appointments  employees who have appointments info
 * @param {array} clinics  all clinics info
 * @param {array} rooms  all rooms info
 * @param {array} appointment_assignable_employees  appointment assignable employees info
 * @param {array} appointment_assignable_employees_all  all appointment assignable employees info
 *
 * @returns {void}  void  set all resources to localStorage
 */
const handleInitialization = (
    employees,
    employees_who_have_appointments,
    clinics,
    rooms,
    appointment_assignable_employees,
    appointment_assignable_employees_all,
    provinces,
    cities
) => {
    handle_employees(employees);
    handle_employees_who_have_appointments(employees_who_have_appointments);
    handle_clinics(clinics);
    handle_patients();
    handle_appointments();
    handle_rooms(rooms);
    handle_appointment_assignable_employees(
        appointment_assignable_employees,
        appointment_assignable_employees_all
    );
    handle_phone_pre_evaluation();
    handle_initial_evaluation();
    handle_provinces(provinces);
    handle_cities(cities);
};
export const handle_employees = (employees) => {
    const activeEmployees = employees.filter((employee) => employee.active);
    localStorage.activeEmployees = JSON.stringify(activeEmployees);
    localStorage.employees = JSON.stringify(employees);
    localStorage.voidEmployee = JSON.stringify({
        uuid: "00000000-0000-0000-0000-000000000000",
        first_name: "Void",
        last_name: "Employee",
        email: "void@void.com",
        active: true,
        position: "Void Position",
        title: "Void Title",
    });
};
export const handle_employees_who_have_appointments = (
    employees_who_have_appointments
) => {
    let appointment_resource_employees = employees_who_have_appointments.map(
        (employee) => ({
            id: employee.uuid,
            text: `${employee?.title ?? ""} ${capitalize.words(
                employee.first_name
            )} ${capitalize.words(employee.last_name)}`,
            filtered: true,
        })
    );
    appointment_resource_employees = [
        {
            id: "00000000-0000-0000-0000-000000000000",
            text: "Void Employee",
            filtered: true,
        },
        ...appointment_resource_employees,
    ];
    localStorage.employees_who_have_appointments = JSON.stringify(
        employees_who_have_appointments
    );
    localStorage.appointment_resource_employees = JSON.stringify(
        appointment_resource_employees
    );
};
export const handle_clinics = (clinics) => {
    localStorage.clinics = JSON.stringify(clinics);
    localStorage.clinicsOptions = JSON.stringify(
        clinics.map((clinic) => [clinic.uuid, capitalize.words(clinic.name)])
    );
};
export const handle_patients = () => {
    localStorage.voidPatient = JSON.stringify({
        uuid: "00000000-0000-0000-0000-000000000000",
        first_name: "Void",
        last_name: "Patient",
        phones: [],
        gender: "",
        city: "",
        province: "",
        email: "void@void.com",
    });
    localStorage.voidCreateNewPatient = JSON.stringify({
        active: "",
        active_memory_concerns: "",
        active_memory_concerns_onset_date: "",
        birth_date: "",
        chart_number: "",
        city: "",
        deceased_date: "",
        education_level: "",
        email: "",
        ethnicity: "",
        race: "",
        race_other: "",
        extraction_of_anonymous_information: "",
        family_doctor_clinic_address: "",
        family_doctor_email: "",
        family_doctor_name: "",
        family_doctor_phone_number: "",
        family_ties: "",
        first_name: "",
        gap: "",
        gender: "",
        health_card_expiration_date: "",
        health_care_number: "",
        how_heard_about_us: "",
        last_name: "",
        licence_plate_number: "",
        name_of_resident_professional: "",
        name_of_treating_professional: "",
        non_potential_studies: "",
        number_of_child: "",
        occupation: "",
        person_to_contact_for_appointment: "",
        pharmacy_address: "",
        pharmacy_fax_number: "",
        pharmacy_name: "",
        pharmacy_phone_number: "",
        phones: [],
        postal_code: "",
        potential: "",
        potential_date: "",
        preferred_communication_method: "",
        preferred_language: "",
        preferred_name: "",
        primary_clinic: "",
        primary_contact_email: "",
        primary_contact_first_name: "",
        primary_contact_last_name: "",
        primary_contact_relationship: "",
        primary_contact_phone_numbers: [],
        province: "",
        referred_by: "",
        secondary_contact_email: "",
        secondary_contact_first_name: "",
        secondary_contact_last_name: "",
        secondary_contact_relationship: "",
        secondary_contact_phone_numbers: [],
        sex: "",
        street_address: "",
        study_code: "",
        suppression_date: "",
        years_of_education: "",
    });
    localStorage.voidOnePatientInfo = JSON.stringify({
        uuid: "00000000-0000-0000-0000-000000000000",
        active: "",
        active_memory_concerns: "",
        active_memory_concerns_onset_date: "",
        age: "",
        allergies: [
            {
                uuid: "00000000-0000-0000-0000-000000000000",
                name: "",
            },
        ],
        appointments: [],
        birth_date: "",
        chart_number: "",
        city: "",
        creation_date: "",
        deceased_date: "",
        dossier_cmo: "",
        education_level: "",
        email: "",
        ethnicity: "",
        race: "",
        race_other: "",
        extraction_of_anonymous_information: "",
        family_doctor_clinic_address: "",
        family_doctor_email: "",
        family_doctor_name: "",
        family_doctor_phone_number: "",
        family_ties: "",
        file_sync_info: "",
        files: [],
        files_sync: "",
        first_name: "",
        gap: "",
        gender: "",
        health_card_expiration_date: "",
        health_care_number: "",
        how_heard_about_us: "",
        intake_forms: [],
        last_name: "",
        latitude: "",
        licence_plate_number: "",
        longitude: "",
        medesync_profile_code: "",
        medical_problems: [
            {
                uuid: "00000000-0000-0000-0000-000000000000",
                active: false,
                employee: JSON.parse(localStorage.voidEmployee),
                end_date: "",
                problem: "",
                self_reported: false,
                start_date: "",
            },
        ],
        medications: [
            {
                uuid: "00000000-0000-0000-0000-000000000000",
                start_date: "",
                end_date: "",
                administration_route: "",
                course_completed: false,
                dosage: "",
                dosage_unit: "",
                dose_frequency: "",
                form: "",
                inactive: false,
                inactive_reason: "",
                indication: "",
                medication: {
                    uuid: "00000000-0000-0000-0000-000000000000",
                    brand: "",
                    din: "",
                    forms: [""],
                    name_en: "",
                    name_fr: "",
                },
            },
        ],
        mmse: [
            {
                uuid: "00000000-0000-0000-0000-000000000000",
                appointment: "",
                clinic: "",
                date: "",
                employee: JSON.parse(localStorage.voidEmployee),
                patient: JSON.parse(localStorage.voidPatient),
                patient_study_profile: "",
                score_numerator: "",
            },
        ],
        moca: [
            {
                uuid: "00000000-0000-0000-0000-000000000000",
                appointment: "",
                clinic: "",
                date: "",
                employee: JSON.parse(localStorage.voidEmployee),
                patient: JSON.parse(localStorage.voidPatient),
                patient_study_profile: "",
                score_numerator: "",
                score_denominator: "",
            },
        ],
        name_of_resident_professional: "",
        name_of_treating_professional: "",
        non_potential_studies: "",
        notes: [
            {
                uuid: "00000000-0000-0000-0000-000000000000",
                content_type: "patient",
                employee: JSON.parse(localStorage.voidEmployee),
                obj_reference: "00000000-0000-0000-0000-000000000000",
                value: "",
            },
        ],
        number_of_child: "",
        occupation: "",
        // todo Studies now is an array, need to query at initialization, like notes
        // ongoing_studies: "",
        person_to_contact_for_appointment: "",
        pharmacy_address: "",
        pharmacy_fax_number: "",
        pharmacy_name: "",
        pharmacy_phone_number: "",
        phones: [],
        postal_code: "",
        potential: "",
        potential_date: "",
        preferred_communication_method: "",
        preferred_language: "",
        preferred_name: "",
        primary_clinic: "",
        primary_contact_email: "",
        primary_contact_first_name: "",
        primary_contact_last_name: "",
        primary_contact_relationship: "",
        primary_contact_phone_numbers: [],
        province: "",
        referred_by: "",
        reminder_date: "",
        secondary_contact_email: "",
        secondary_contact_first_name: "",
        secondary_contact_last_name: "",
        secondary_contact_relationship: "",
        secondary_contact_phone_numbers: [],
        sex: "",
        social: "",
        street_address: "",
        study_code: "",
        suppression_date: "",
        treatments: [
            {
                uuid: "00000000-0000-0000-0000-000000000000",
                start_date: "",
                end_date: "",
                treatment: "",
                inactive: false,
                inactive_reason: "",
                indication: "",
                course_completed: false,
                patient: "00000000-0000-0000-0000-000000000000",
                patient_medication: {
                    uuid: "00000000-0000-0000-0000-000000000000",
                    administration_route: "",
                    dosage: "",
                    dosage_unit: "",
                    dose_frequency: "",
                    form: "",
                    medication: {
                        uuid: "00000000-0000-0000-0000-000000000000",
                        brand: "",
                        din: "",
                        forms: [""],
                        name_en: "",
                        name_fr: "",
                    },
                },
            },
        ],
        vitals: [
            {
                uuid: "00000000-0000-0000-0000-000000000000",
                appointment: "",
                clinic: "",
                creatinine: "",
                date: "",
                diastolic_pressure: "",
                employee: JSON.parse(localStorage.voidEmployee),
                patient: JSON.parse(localStorage.voidPatient),
                patient_study_profile: "",
                glucose_mmol_per_l: "",
                heart_rate: "",
                oxygen_level: "",
                respiration_rate: "",
                systolic_pressure: "",
                temperature_c: "",
                waist_circumference_cm: "",
                weight_kg: "",
                height_cm: "",
                bmi: "",
            },
        ],
        years_of_education: "",
    });
};
export const handle_appointments = () => {
    localStorage.voidAppointment = JSON.stringify({
        startDate: "",
        endDate: "",
        title: "Void Appointment",
        id: "00000000-0000-0000-0000-000000000000",
        medesync_id: "123123123",
        creation_date: "",
        cancellation_datetime: "",
        cancellation_note: "",
        cancellation_type: "",
        study_partner_required_duration_min: 0,
        appointment_type: "voidtype",
        appointment_status: "to_confirm",
        patient: JSON.parse(localStorage.voidPatient),
        patient_study_profile: "",
        clinic: "",
        appointment_creator: JSON.parse(localStorage.voidEmployee),
        room: "",
        employees_uuids: ["00000000-0000-0000-0000-000000000000"],
        employees: [JSON.parse(localStorage.voidEmployee)],
        notes: [],
    });
};
export const handle_rooms = (rooms) => {
    localStorage.rooms = JSON.stringify(rooms);
    localStorage.roomsOptions = JSON.stringify(
        rooms.map((room) => [room.uuid, capitalize.words(room.alias)])
    );
    localStorage.roomsUUIDs = JSON.stringify(rooms.map((room) => room.uuid));
    localStorage.ottawaRoomsOptions = JSON.stringify(
        rooms
            .filter((room) => room.clinic.name === "ottawa")
            .map((room) => [room.uuid, capitalize.words(room.alias)])
    );
    localStorage.gatineauRoomsOptions = JSON.stringify(
        rooms
            .filter((room) => room.clinic.name === "gatineau")
            .map((room) => [room.uuid, capitalize.words(room.alias)])
    );
    localStorage.montrealRoomsOptions = JSON.stringify(
        rooms
            .filter((room) => room.clinic.name === "montreal")
            .map((room) => [room.uuid, capitalize.words(room.alias)])
    );
};
export const handle_appointment_assignable_employees = (
    appointment_assignable_employees,
    appointment_assignable_employees_all
) => {
    localStorage.appointment_assignable_employees = JSON.stringify(
        appointment_assignable_employees
    );
    localStorage.appointment_assignable_employees_all = JSON.stringify(
        appointment_assignable_employees_all
    );
};
export const medicationOrTherapyTemplate = {
    isYes: "notDecidedYet",
    startDate: {
        year: "",
        month: "",
        day: "",
    },
    treatment: "",
    indication: "",
    inactive: "",
    inactive_reason: "",
    course_completed: "",
    endDate: {
        year: "",
        month: "",
        day: "",
    },
    medicationContext: {
        administration_route: "",
        dosage: "",
        dosage_unit: "",
        dose_frequency: "",
        form: "",
        medication: {
            uuid: "00000000-0000-0000-0000-000000000000",
            brand: "",
            din: "",
            forms: [""],
            name_en: "",
            name_fr: "",
        },
    },
};
export const handle_phone_pre_evaluation = () => {
    localStorage.voidPhonePreEvaluation = JSON.stringify({
        uuid: "00000000-0000-0000-0000-000000000000",
        creation_date: "",
        patient: JSON.parse(localStorage.voidPatient),
        employee: JSON.parse(localStorage.voidEmployee),
        type: "phone-pre-evaluation",
        form: {
            version: "PPE.0.2.20241104",
            versionDescription: `Enable user to add multiple treatment records for one cancer record. Reference: https://github.com/Recherches-Neuro-Hippocampe/omc-data-frontend/issues/1786`,
            isStillInterested: "notDecidedYet",
            notInterestedReason: "",
            notInterestedDate: "",
            isHaveSpareTime: "notDecidedYet",
            isRescheduleSucceed: "notDecidedYet",
            rescheduleToThisDate: "",
            isIntact: "yes",
            consentToCollectData: "notDecidedYet",
            reasonOfTheConsultation: {
                whatIsTheReasonOfTheConsultation: "",
                areYourDTDActivitiesAffectedByYourMemoryChanges: "",
                dateOfOnset: {
                    year: "",
                    month: "",
                    day: "",
                },
            },
            haveYouEverBeenDiagnosedWithCancer: "notDecidedYet",
            cancerRecords: [],
            haveYouEverSufferedFromAStroke: "notDecidedYet",
            strokeRecords: [],
            doYouSufferFromAnyUnstableMedicalCondition: "notDecidedYet",
            unstableMedicalConditionRecords: [],
            doYouHaveACardiacPaceMaker: "notDecidedYet",
            yesHaveACardiacPaceMakerAndSoOn: [],
            haveYouEverBeenDiagnosedWithANeurodegenerativeDisease:
                "notDecidedYet",
            diagnosedNeurodegenerativeDiseases: [],
            doYouSufferFromEpilepsy: "notDecidedYet",
            epilepsyRecords: [],
            doYouHaveAnyUpcomingSurgeriesPlanned: "notDecidedYet",
            upcomingSurgeriesRecords: [],
            mandatoryTutorshipOrPowerOfAttorney: {
                isYes: "notDecidedYet",
                details: "",
            },
            wouldYouBeInterestedInParticipatingInClinicalResearch:
                "notDecidedYet",
            consent2AccessOrRequestMedicalHistory: {
                isYes: "notDecidedYet",
                pharmacyName: "",
                pharmacyAddress: "",
                familyPhysician: "",
                familyPhysicianAddress: "",
                OHIPNumber: "",
                RAMQNumber: "",
                other: "",
            },
            doYouHaveIntakePartnerInfo: "notDecidedYet",
            intakePartnerInfo: {
                firstName: "",
                lastName: "",
                relationshipToPatient: "",
                phone: [],
                email: "",
            },
            doYouHaveAdditionalInformation: "notDecidedYet",
            additionalInformation: [],
            areYouComfortableUsingCertainTechnologicalTools: {
                areYouComfortableUsingCertainTechnologicalTools:
                    "notDecidedYet",
                couldSomeoneAroundYouAssistYou: "notDecidedYet",
                yesSomeoneAroundYouAssistYou: "",
            },
            howWouldYouPreferToHaveYourInitialAssessment: "",
            scheduleAnIntake: "notDecidedYet",
        },
    });
};
export const handle_initial_evaluation = () => {
    const medicalHistoryTemplate = {
        condition: "",
        otherCondition: "",
        dateOfOnset: {
            year: "",
            month: "",
            day: "",
        },
        isOnGoing: "",
        endDate: {
            year: "",
            month: "",
            day: "",
        },
        medicationOrTherapy: medicationOrTherapyTemplate,
        surgery: {
            isYes: "notDecidedYet",
            date: {
                year: "",
                month: "",
                day: "",
            },
            details: "",
        },
    };
    localStorage.voidInitialEvaluation = JSON.stringify({
        uuid: "00000000-0000-0000-0000-000000000000",
        creation_date: "",
        patient: JSON.parse(localStorage.voidPatient),
        employee: JSON.parse(localStorage.voidEmployee),
        type: "initial-evaluation",
        form: {
            version: "IE.0.0.20241022",
            versionDescription: `Initial Evaluation Form Version 0.0.20241022`,
            howIsTheVisitConducted: "",
            informationAndConsentForms: {
                initialEvaluationInformationAndConsentForm: "",
                consent2DataTransfer: "",
                caregiverConsent: "",
                ApoEConsent: "",
            },
            demographicInformation: {
                yearsOfEducation: "",
                highestGradeOfEducation: "",
                lastOccupation: "",
                currentEmploymentStatus: "",
                otherEmploymentStatus: "",
                dateOfRetirement: {
                    year: "",
                    month: "",
                    day: "",
                },
                numberOfChildren: "",
                maritalStatus: "",
                otherMaritalStatus: "",
                currentLivingSituation: [],
                otherCurrentLivingSituation: "",
                housingSituation: "",
                otherHousingSituation: "",
                ethnicity: "",
                race: "",
                otherRace: "",
                doYouConsiderYourself2BeASnowbird: "",
                doYouHavePrivateHealthInsurance: "",
            },
            situation: {
                haveYouBeenHospitalizedInTheLastYear: {
                    whenWereYouAdmitted: {
                        year: "",
                        month: "",
                        day: "",
                    },
                    whichHospitalWereYouIn: "",
                    howLongDidYouStay: "",
                    additionalInformation: "",
                },
                haveYouEverParticipatedInAClinicalTrial: {
                    nameOfTheTrial: "",
                    whenWasTheTrial: {
                        year: "",
                        month: "",
                        day: "",
                    },
                },
            },
            familyHistoryOfAlzheimersDisease: {
                anyOfYourBiologicalRelativesDiagnosedWithADDementiaND: [
                    {
                        relationship: "",
                        ageOfOnset: "",
                        diagnosis: "",
                        quantityOfPeopleDiagnosed: "",
                        comment: "",
                    },
                ],
                haveYouEverCompletedGeneticTesting4AD: {
                    yesButRefuse2Disclose: "",
                    whatTest: "",
                    result: "",
                },
            },
            lifeHabits: {
                measure: {
                    height: "",
                    weight: "",
                    bmi: "",
                },
                sleep: {
                    hoursOfSleep: "",
                    diagnosedSleepingDisorder: "",
                    dateOfOnset: {
                        year: "",
                        month: "",
                        day: "",
                    },
                    takingAnyMedication2HelpWithYourSleep: "",
                },
                hoursOfPhysicalActivity: "",
                hoursOfSocialization: "",
                describeYourDiet: "",
                alcohol: {
                    neverConsumed: "",
                    endDateAsAFormerConsumer: {
                        year: "",
                        month: "",
                        day: "",
                    },
                    currentConsumer: {
                        beers: {
                            amount: "",
                            frequency: "",
                        },
                        wine: {
                            amount: "",
                            frequency: "",
                        },
                        spirits: {
                            amount: "",
                            frequency: "",
                        },
                        total: "",
                        isASubstanceAbuse: "",
                    },
                },
                tobaccoOrNicotine: {
                    neverConsumed: "",
                    endDateAsAFormerConsumer: {
                        year: "",
                        month: "",
                        day: "",
                    },
                    currentConsumer: [
                        {
                            type: "",
                            otherType: "",
                            amount: "",
                            frequency: "",
                        },
                    ],
                },
                recreationalDrugUsage: {
                    neverConsumed: "",
                    records: [
                        {
                            consumeStatus: "",
                            type: "",
                            startDate: {
                                year: "",
                                month: "",
                                day: "",
                            },
                            endDate: {
                                year: "",
                                month: "",
                                day: "",
                            },
                            amount: "",
                            frequency: "",
                            details: "",
                        },
                    ],
                    isASubstanceAbuse: "",
                },
            },
            medicalHistoryAllergiesIntolerance: [
                {
                    type: "",
                    dateOfOnset: {
                        year: "",
                        month: "",
                        day: "",
                    },
                    detailsOfReaction: "",
                    dateOfLastReaction: {
                        year: "",
                        month: "",
                        day: "",
                    },
                    medicationOrTherapy: medicationOrTherapyTemplate,
                },
            ],
            medicalHistoryNeurological: [medicalHistoryTemplate],
            medicalHistoryEENT: [
                { ...medicalHistoryTemplate, conditionSpecification: "" },
            ],
            medicalHistoryCardiovascular: [medicalHistoryTemplate],
            medicalHistoryRespiratory: [medicalHistoryTemplate],
            medicalHistoryPsychiatric: [
                {
                    ...medicalHistoryTemplate,
                    yesOngoingDepression: {
                        areYouBasicallySatisfiedWithYourLife: "",
                        haveYouDroppedManyOfYourActivitiesAndInterests: "",
                        doYouFeelThatYourLifeIsEmpty: "",
                        doYouOftenGetBored: "",
                        areYouInGoodSpiritsMostOfTheTime: "",
                        areYouAfraidThatSomethingBadIsGoing2Happen2You: "",
                        doYouFeelHappyMostOfTheTime: "",
                        doYouOftenFeelHelpless: "",
                        doYouPrefer2StayHomeRatherThanGoingOutAndDoingNewThings:
                            "",
                        doYouFeelYouHaveMoreProblemsWithMemoryThanMostPeople:
                            "",
                        doYouThinkItIsWonderful2BeAlive: "",
                        doYouFeelPrettyWorthlessTheWayYouAreNow: "",
                        doYouFeelFullOfEnergy: "",
                        doYouFeelThatYourSituationIsHopeless: "",
                        doYouThinkThatMostPeopleAreBetterOffThanYouAre: "",
                    },
                    score: "",
                },
            ],
            medicalHistoryGastrointestinal: [medicalHistoryTemplate],
            medicalHistoryEndocrineMetabolic: [medicalHistoryTemplate],
            medicalHistoryGenitourinary: [medicalHistoryTemplate],
            medicalHistoryOther: [medicalHistoryTemplate],
            medicationAndHealthSupplements: [
                { medicationOrTherapy: medicationOrTherapyTemplate },
            ],
            cognitionMoodAndBehavior: {
                cognition: {
                    doYouForgetRecentInformationSuchAsConversationsOrRecentActivities:
                        "",
                    doYouForgetFamilyCelebrationsEventsOrAppointments: "",
                    doYouFindYourselfSearching4WordsNamesOfPeopleObjectsPlaces:
                        "",
                    doYouHaveAnyDifficultyCompletingSentences: "",
                    doYouHaveTroublePerformingOrConcentratingOnYourHobbies: "",
                    doYouFindItDifficult2MaintainYourAttentionOrConcentration:
                        "",
                    doYouNoticeAnyDifficultiesWithYourJudgementProblemSolvingAbilities:
                        "",
                    doYouHaveAnyTroubleWithTemporalOrientation: "",
                    doYouHaveAnyDifficultiesWithSpatialOrientation: "",
                },
                mood: [
                    {
                        itemName: "",
                        itemNameOther: "",
                        comment: "",
                    },
                ],
                behavior: [
                    {
                        itemName: "",
                        itemNameOther: "",
                        comment: "",
                    },
                ],
            },
            ADLsAndiADLs: {
                ADLs: "",
                iADLs: "",
            },
        },
    });
};
export const handle_provinces = (provinces) => {
    localStorage.provinces = JSON.stringify(provinces);
    localStorage.provincesSimplifiedArray = JSON.stringify(
        provinces.map((province) => province.choice)
    );
    localStorage.provincesOptions = JSON.stringify(
        provinces.map((province) => [province.choice, province.representation])
    );
};
export const handle_cities = (cities) => {
    localStorage.cities = JSON.stringify(cities);
    let citiesSimplified = {};
    for (const province in cities) {
        citiesSimplified[province] = cities[province].map(
            (city) => city.choice
        );
    }
    localStorage.citiesSimplifiedArray = JSON.stringify(citiesSimplified);
    let citiesOptions = {};
    for (const province in cities) {
        citiesOptions[province] = cities[province].map((city) => [
            city.choice,
            city.representation,
        ]);
    }
    localStorage.citiesOptions = JSON.stringify(citiesOptions);
};
export default handleInitialization;
